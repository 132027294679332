<template>
  <div class="menu-item menu-search">
    <UTooltip text="Open Search" :shortcuts="['⌘', 'K']" class="flex">
      <a class="menu-item-link text-white flex items-center" href="/search" @click.prevent="isOpen = true" role="button">
        <UIcon name="i-heroicons-magnifying-glass-16-solid" class="w-5 h-5 " />
      </a>
    </UTooltip>

    <UModal v-model="isOpen" :ui="ui.modal">
      <LazyUCommandPalette :ui="ui.command" :auto-select="false" nullable :groups="groups" @update:model-value="onSelect" placeholder="Search anything about Kudosity..."></LazyUCommandPalette>
    </UModal>
  </div>
</template>

<script setup lang="ts">
  const config = useRuntimeConfig().public;
  const { search } = useAlgoliaSearch(config.ALGOLIA_INDEX);
  const isOpen = ref(false);

  const router = useRouter();
  const results = ref([]);

  defineShortcuts({
    meta_k: {
      usingInput: true,
      whenever: [() => !isOpen.value],
      handler: () => {
        isOpen.value = !isOpen.value;
      },
    },

    escape: {
      usingInput: true,
      whenever: [isOpen],
      handler: () => {
        isOpen.value = false;
      },
    },

    "meta_/": {
      usingInput: true,
      whenever: [isOpen],
      handler: () => {
        navigateTo("https://help.kudosity.com/s/submit-ticket", { external: true });
      },
    },
  });

  const onSelect = (option: { click: () => void; to: string | URL; href: string | URL }) => {
    isOpen.value = false;
    if (option?.click) {
      option.click();
    } else if (option?.to) {
      router.push(option.to);
    } else if (option?.href) {
      window.open(option.href, "_blank");
    }
  };
  const groups = [
    {
      key: "links",
      label: "Quick links",
      commands: [
        {
          id: "start-trial",
          icon: "i-heroicons-rocket-launch",
          label: "Start a trial",
          to: "/trial",
        },
        {
          id: "pricing",
          icon: "i-heroicons-ticket",
          label: "Pricing",
          to: "/pricing",
        },
        {
          id: "careers",
          icon: "i-heroicons-briefcase",
          label: "Careers",
          to: "/careers",
          suffix: "– we're hiring!",
        },
        {
          id: "contact-us",
          icon: "i-heroicons-chat-bubble-left-ellipsis",
          label: "Contact Us",
          to: "/contact-us",
        },
        {
          id: "submit-ticket",
          icon: "i-heroicons-question-mark-circle",
          label: "Submit a ticket",
          href: "https://help.kudosity.com/s/submit-ticket",
          shortcuts: ["⌘", "?"],
        },
      ],
    },
    {
      key: "pages",
      label: (q: string) => q && `Pages matching "${q}"...`,
      search: async (q: string) => {
        const result = await search({ query: q });
        results.value = result;
        return result.hits
          .map((hit: any) => {
            let icon = "i-heroicons-document-text";

            if (hit?.breadcrumbs?.includes("News")) icon = "i-heroicons-newspaper";
            if (hit?.breadcrumbs?.includes("Customer Stories")) icon = "i-heroicons-users";
            if (hit?.breadcrumbs?.includes("Articles")) icon = "i-heroicons-chat-bubble-left-right";
            if (hit?.breadcrumbs?.includes("Legal")) icon = "i-heroicons-globe-alt";
            if (hit?.breadcrumbs?.includes("Guides")) icon = "i-heroicons-academic-cap";
            if (hit?.pathname?.includes("/tag/")) return null;

            if (hit?.breadcrumbs?.includes(hit.title)) hit?.breadcrumbs?.pop();
            const suffix = hit?.breadcrumbs?.slice(0, 2).join(" > ").trim();

            return {
              id: hit.objectID,
              icon: icon,
              label: hit.title,
              to: hit.url,
              suffix: suffix === hit.title ? '' : suffix,
            };
          })
          .filter(Boolean);
      },
    },
  ];

  const ui = {
    modal: {
      wrapper: "z-[5000] fixed",
      width: "w-full sm:max-w-2xl",
    },
    command: {
      input: {
        base: "w-full h-14 px-4 placeholder-gray-400 dark:placeholder-gray-500 bg-transparent border-0 text-gray-900 dark:text-white focus:ring-0 focus:outline-none",
      },
      group: {
        label: "px-2 my-2 text-xs font-semibold text-gray-500 dark:text-gray-400",
        command: {
          base: "flex justify-between select-none cursor-default items-center rounded-md px-2 py-2 gap-2 relative w-full",
          active: "bg-gray-200 dark:bg-gray-700/50 text-gray-900 dark:text-white",
          container: "flex items-center gap-3 min-w-0 w-full",
          label: 'flex items-center gap-1.5 min-w-0 w-full',
          icon: {
            base: "flex-shrink-0 w-5 h-5",
            active: "text-gray-900 dark:text-white",
            inactive: "text-gray-400 dark:text-gray-500",
          },
          avatar: {
            size: "2xs" as const,
          },
        },
      },
    },
  };
</script>
