<template>
  <section blog-grid v-editable="blok" v-if="pending === false && stories">
    <div class="container">
      <div class="row row-filter">
        <TabsHorizontal v-model="category" :tabs="[`Recent ${blok.title}`, ...categories]" v-if="!props.blok.hide_filters" />
        <TabsHorizontal v-else v-model="category" :tabs="[`${blok.title}`]"  />
        <div class="section-search">
          <SearchArticles v-model="search" />
        </div>
      </div>
      <div class="row">
        <template v-if="stagedArticles.length > 0">
          <div class="section-grid">
            <ArticleCard v-for="(article, a) in stagedArticles" :article="article" :key="article.id" />
          </div>
        </template>
        <template v-else>
          <div class="message-articles-empty">No {{ blok.title.toLowerCase() }} found <span v-html="`for <i>${search}</i>`" v-if="search.length > 0"></span></div>
        </template>
      </div>
      <div class="row" v-if="filteredArticles.length > stagedArticles.length">
        <div class="section-buttons">
          <a class="btn btn-primary" :href="`/resources/articles`" @click.prevent="viewMore"> View more </a>
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/blog-grid.scss" lang="scss"></style>

<script setup>
const props = defineProps({ blok: Object });
const search = ref("");
const page = ref(1);
const isPreview = useRuntimeConfig().public.CMS_ENV !== "production";
const tag = ref("");
const filter_query = {};

if (props.blok.category?.length > 0) {
  filter_query.category = {
    in: props.blok.category ?? "",
  };
}

const { data: stories, pending } = await useAsyncData(`blog-grid`, async () => {
  const { data } = await useStoryblokApi().get("cdn/stories/", {
    version: isPreview ? "draft" : "published",
    resolve_links: "url",
    content_type: [props.blok.type,'page'],
    sort_by: `${props.blok.sort || "updated_at"}:${props.blok.order || "desc"}`,
    filter_query,
    starts_with: props.blok.folder || 'resources/articles/',
    excluding_ids: props.blok.excluding_ids || '',
    excluding_slugs: props.blok.excluding_slugs || ''
  });
  return data?.stories;
});

const articles = ref(stories.value);

const category = ref(textTitleCase(`Recent ${props.blok.title}`));

if(props.blok.hide_filters){
  category.value = textTitleCase(`${props.blok.title}`);
}

watch(category, () => {
  page.value = 1;
  search.value = "";
});

const categories = computed(() => {
  return getUniqueValues(articles.value, "content.category").filter((c) => c.trim().length > 0).sort();
});

const filteredArticles = computed(() => {
  if (search.value.trim().length === 0) {
    if(props.blok?.hide_filters) return articles.value;
    if (category.value === textTitleCase(`Recent ${props.blok.title}`)) return articles.value;

    return articles.value.filter((a) => {
      return a.content.category === category.value;
    });
  } else {
    return articles.value.filter((a) => {
      if (tag.value) {
        return a.content.tags.value.indexOf(tag.value) >= 0;
      }

      const searchText = [a.name, a.content?.seo?.title, a.content.category, a.content?.seo?.description, a.content.tags.value.join(" ")].join(" ").toLowerCase();
      return searchText.includes(search.value.trim().toLowerCase());
    });
  }
});

const stagedArticles = computed(() => {
  if (filteredArticles.value.length < props.blok.limit) return filteredArticles.value;
  const start = 0;
  const end = page.value * (props.blok.limit || 6);
  return filteredArticles.value.slice(start, end);
});

const viewMore = () => {
  page.value++;
};

onMounted(() => {
  if (process.browser) {
    const urlParams = new URLSearchParams(window.location.search);
    const hasCategory = urlParams.get("category") || false;
    const hasTag = urlParams.get("tag") || false;
    if (hasCategory) {
      category.value = hasCategory;

      const el = document.querySelector(`[blog-grid]`);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (hasTag) {
      tag.value = hasTag;

      const el = document.querySelector(`[blog-grid]`);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
});
</script>
