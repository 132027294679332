<template>
  <a :href="getLinkFieldUrl(blok?.url)" v-editable="blok" :class="['btn', 'btn-' + blok.theme, blok.icon && `btn-icon-${blok.icon}`]" :aria-label="blok.label" :target="blok.url?.target" role="button">{{ blok.label }} </a>
</template>

<script setup>
  const props = defineProps({ blok: Object });

  onMounted(() => {
    if(!props?.blok?.url)
    console.log(props.blok);
  });
</script>
