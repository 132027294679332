import routerOptions0 from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/router.options.js";
const configRouterOptions = {
  strict: true,
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = false
export default {
...configRouterOptions,
...routerOptions0,
}