import payload_plugin_qLmFnukI99 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BqLkNUef68 from "/opt/build/repo/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import slideovers_X7whXyrIWR from "/opt/build/repo/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/opt/build/repo/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/opt/build/repo/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/opt/build/repo/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/opt/build/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_adVF2uRka6 from "/opt/build/repo/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_2TTjvwQPRx from "/opt/build/repo/node_modules/nuxt-csurf/dist/runtime/plugin.js";
import components_client_ZB1W0EeH0E from "/opt/build/repo/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import plugin_hBLWEPkA3b from "/opt/build/repo/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import analytics_ABBiEqcBsv from "/opt/build/repo/plugins/analytics.js";
import appendUTMParameters_client_kzctSI6AEp from "/opt/build/repo/plugins/appendUTMParameters.client.ts";
import disableDarkMode_client_HieGFtTCWU from "/opt/build/repo/plugins/disableDarkMode.client.ts";
import replaceEmojis_client_kVOQJ9V8Qd from "/opt/build/repo/plugins/replaceEmojis.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BqLkNUef68,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  plugin_adVF2uRka6,
  plugin_2TTjvwQPRx,
  components_client_ZB1W0EeH0E,
  plugin_hBLWEPkA3b,
  analytics_ABBiEqcBsv,
  appendUTMParameters_client_kzctSI6AEp,
  disableDarkMode_client_HieGFtTCWU,
  replaceEmojis_client_kVOQJ9V8Qd
]